
import React, { useEffect, useState, useRef } from 'react';
import Hero from './Hero.js';
import LandingPage from './LandingPage.js';
import splashart from '../assets/splashart.jpg';
import './CSS/Home.css';
import Popup from 'reactjs-popup';
import NewPassword from './NavBar/SignIn/NewPassword.js';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faPeopleGroup, faCrown, faGlobe, faUserShield, faRankingStar, faGears, faMinusCircle, faPlusCircle, faBusinessTime, faGamepad, faUsersGear, faUserGraduate } from '@fortawesome/free-solid-svg-icons'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import axios from 'axios';

import { Helmet } from 'react-helmet';

import { useAuth } from '../contexts/AuthContext.js';

import './CSS/Accordion.css';

export default function Home() {

    const helmetTags = require('../constants/helmet-tags.json');

    const { random_id } = useParams();

    const { getAccessToken } = useAuth();
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal);
    
    const [active, setActive] = useState('none');
    const [selected, setSelected] = useState('skill');
    const timer = useRef(null);
    const [timerActive, setTimerActive] = useState(false);
    const [timerCount, setTimerCount] = useState(0);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [textWidth, setTextWidth] = useState(1); 
    const bannerText = " USE CODE SAVE10 FOR 10% OFF YOUR FIRST ORDER 🚀";
    const bannerTextRef = React.createRef();
    const [repeatedText, setRepeatedText] = useState(bannerText);
    const [resetPassword, setResetPassword] = useState(false);

    const timerMax = 5;

    useEffect(() => {
        startTimer();
        calculateTextWidth();
        if(random_id) {
            axios.post(`${process.env.REACT_APP_API_URL}/user/verify-discord/${random_id}`, {}, {
                headers: { 
                    Authorization: getAccessToken() 
                },
            }).then(response => {
                MySwal.fire({
                    title: 'Discord Verification',
                    text: 'Your Discord account has been successfully verified!',
                    icon: 'success',
                    confirmButtonText: 'Ok'
                }).then(() => {
                    navigate('/');
                });
            }).catch(error => {
                console.error(error);
                MySwal.fire({
                    title: 'Discord Verification',
                    text: 'An error occurred while verifying your Discord account. Please try again later.',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                }).then(() => {
                    navigate('/');
                });
            });
        } else {
            if(!resetPassword) {
                MySwal.fire({
                    title: '18% OFF for a limited time!',
                    text: 'Use code: SPRING18 for 18% off your order! 🎁',
                    icon: 'success',
                    confirmButtonText: 'Ok'
                });
            }
        }
    }, []);


    useEffect(() => {
        const handleTimer = () => {
            setTimerCount(timerCount => timerCount + 1);
        };

        if (timerActive) {
            timer.current = setInterval(handleTimer, 1000);
        }

        return () => {
            clearInterval(timer.current);
        };
    }, [timerActive]);

    useEffect(() => {
        if (timerCount === timerMax) {
            changeSelected();
            resetTimer();
        }
    }, [timerCount]);

    const startTimer = () => {
        setTimerActive(true);
    };

    const stopTimer = () => {
        setTimerActive(false);
        setTimerCount(0);
    };

    const resetTimer = () => {
        setTimerCount(0);
    };

    const changeSelected = () => {
        if (selected === 'skill') {
            setSelected('experience');
        } else if (selected === 'experience') {
            setSelected('professionalism');
        } else if (selected === 'professionalism') {
            setSelected('selective');
        } else if (selected === 'selective') {
            setSelected('skill');
        }
    };

    const handleSelectionChange = (newSelection) => {
        stopTimer();
        setSelected(newSelection);
        startTimer();
    };

    const calculateTextWidth = () => {
        const textElement = bannerTextRef.current;
        if (textElement) {
          setTextWidth(textElement.offsetWidth);
        }
    };

    const handleResize = () => {
        setScreenWidth(window.innerWidth);
      };
    
      useEffect(() => {
        // Set initial text width on mount
        calculateTextWidth();
    
        // Attach event listeners for resize
        window.addEventListener('resize', handleResize);
    
        // Cleanup event listeners on unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []); // Empty dependency array ensures this effect runs only once on mount
    
      useEffect(() => {
        // Calculate how many times the text fits into the width
        const textFits = Math.ceil(screenWidth / textWidth);
        if(bannerText.length > 0) { 
            const repeatedText = bannerText.repeat(textFits + 2); // Add extra repetitions
            setRepeatedText(repeatedText);
        }
    
        // Periodically move the marginLeft
        const intervalId = setInterval(() => {
            if(!bannerTextRef.current) return;
            const currentMargin = parseInt(bannerTextRef.current.style.marginLeft, 10) || 0;
            
            if (Math.abs(currentMargin) > textWidth * 2) {
                bannerTextRef.current.style.marginLeft = 0 + 'px';
            } else {
                bannerTextRef.current.style.marginLeft = (currentMargin - 1) + 'px';
            }
        }, 30);
        return () => clearInterval(intervalId);
    }, [screenWidth, textWidth, bannerText, bannerTextRef]);
      

    const toggleAccordion = (faq) => {
        active === faq ? setActive('none') : setActive(faq);
    };

    //get the link from the url and see if it contains reset-password
    useEffect(() => {
        if(window.location.href.includes('reset-password')) {
            setResetPassword(true);
        }
    }, []);


    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{helmetTags.home.title}</title>
                <meta name="description" content={helmetTags.home.description}/>
                <link rel="canonical" href={helmetTags.home.link}/>
            </Helmet>
            <Popup open={resetPassword} closeOnEscape closeOnDocumentClick modal nested>
                <NewPassword key={'reset-password'} closeModal={() => navigate('/')}/>
            </Popup>
            <div className='home'>
                {process.env.REACT_APP_WEBSITE_ENABLED === 'true' ? <Hero backgroundImg={splashart} /> : <LandingPage backgroundImg={splashart} />}
            </div>
            <div className="banner">
                <div className="banner-container">
                    <h3 ref={bannerTextRef}>{bannerText}</h3>
                    <h3>{repeatedText}</h3>
                </div>
            </div>
            {process.env.REACT_APP_WEBSITE_ENABLED === 'true'&&<div className='content-main'>
                <div className='content-inner background'style={{marginBottom:'-5rem'}}>
                    <div className='content-container title fill'>
                        <h2>Why Choose Us</h2> 
                        <h3>WE ARE COMMITTED TO YOUR SUCCESS</h3>
                    </div>
                    <div className='content-container'>
                        <div className='content-separator'>
                            <div className='content-box icon'>
                                <div className='content-subtitle'>
                                    <div className='content-icon'>
                                        <FontAwesomeIcon icon={faCircleCheck} />
                                    </div>
                                    <h3>
                                        Proven Track Record
                                    </h3>
                                </div>
                                <div className='content-body'>
                                    <p>
                                    Over the past 10 years, we have successfully boosted countless gamers, helping them achieve their desired ranks and accomplishments.
                                    </p>
                                </div>
                            </div>
                            <div className='content-box icon'>
                                <div className='content-subtitle'>
                                    <div className='content-icon'>
                                        <FontAwesomeIcon icon={faPeopleGroup} />
                                    </div>
                                    <h3>
                                        Experienced Team
                                    </h3>
                                </div>
                                <div className='content-body'>
                                    <p>
                                    Our team consists of highly skilled and experienced gamers who understand the nuances of each game, ensuring that your boosting experience is seamless and efficient.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='content-separator'>
                            <div className='content-box icon'>
                                <div className='content-subtitle'>
                                    <div className='content-icon'>
                                        <FontAwesomeIcon icon={faCrown} />
                                    </div>
                                    <h3>
                                        Customer Satisfaction
                                    </h3>
                                </div>
                                <div className='content-body'>
                                    <p>
                                    Customer satisfaction is at the core of our values. We prioritize your needs and work tirelessly to exceed your expectations.
                                    </p>
                                </div>
                            </div>
                            <div className='content-box icon'>
                                <div className='content-subtitle'>
                                    <div className='content-icon'>
                                        <FontAwesomeIcon icon={faGlobe} />
                                    </div>
                                    <h3>
                                        Wide Range of Services
                                    </h3>
                                </div>
                                <div className='content-body'>
                                    <p>
                                    Whether you're aiming for a higher rank, seeking personalized coaching, or looking for specific in-game achievements, we offer a diverse range of services tailored to meet your gaming goals.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='content-shape'>
                        <svg width="100%" height="100%" viewBox='0 0 100 100' preserveAspectRatio='none'>
                            <defs>
                                <filter id="dropshadow">
                                    <feGaussianBlur in="SourceAlpha" stdDeviation="4" />
                                </filter>
                            </defs>
                            <path d="m -3e-6,100.06612 100,-89.416672 H 110.58333 V 110.64945 H -10.583333 v -10.58333 z" fill="rgba(0,0,0,0.5)" filter='url(#dropshadow)'/>
                            <path d="m -3e-6,100.06612 100,-89.416672 H 110.58333 V 110.64945 H -10.583333 v -10.58333 z" fill="var(--background-layer-1)" stroke='var(--background-layer-1)' strokeWidth={1}/>
                        </svg>
                    </div>
                </div>
                <div className='content-inner fullwidth'>
                    
                    <div className='content-item gray'>
                        <div className='content-container title'>
                            <h2>Our Selective Process</h2>
                            <h3>CRAFTING EXCELENCE, NOT COMPROMISE</h3>
                        </div>
                        <div className='content-container'>
                            <div className='content-background-color'>
                                <p>
                                At EloFactory.gg, we understand that the key to delivering exceptional gaming experiences lies in the expertise and dedication of our boosters. We take great pride in our stringent selection process, ensuring that we handpick individuals who not only possess unparalleled gaming skills but also share our commitment to professionalism and customer satisfaction.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='content-item fullwidth'>
                    <div className='content-shape'>
                        <svg width="100%" height="100%" viewBox='0 0 100 100' preserveAspectRatio='none'>
                            <path d="M 100,0 0,100 V 0 h 100" fill="var(--background-layer-1)" stroke='var(--background-layer-1)' strokeWidth={1}/>
                        </svg>
                    </div>
                    <div className='content-container title fill-special'>
                        <h2>THE ELOFACTORY DIFFERENCE</h2>
                        <h3>WHY CHOOSE US</h3>
                    </div>
                    <div className='content-container'>
                        <div className='content-selector'>
                            <button className={`${selected==='skill' ? 'active' : 'inactive'}`} onClick={() => handleSelectionChange('skill')}>
                                <h3>
                                    Skill and Expertise
                                </h3>
                            </button>
                            <button className={`${selected==='experience' ? 'active' : 'inactive'}`} onClick={() => handleSelectionChange('experience')}>
                                <h3>
                                    Experience Matters
                                </h3>
                            </button>
                            <button className={`${selected==='professionalism' ? 'active' : 'inactive'}`} onClick={() => handleSelectionChange('professionalism')}>
                                <h3>
                                    Professionalism
                                </h3>
                            </button>
                            <button className={`${selected==='selective' ? 'active' : 'inactive'}`} onClick={() => handleSelectionChange('selective')}>
                                <h3>
                                    Selective Recruitment
                                </h3>
                            </button>
                        </div>
                        <div className={`content-box${selected==='skill' ? ' active fade-animation' : ' inactive'}`}>
                            <div className='content-subtitle'>
                                <div className='content-icon'>
                                    <FontAwesomeIcon icon={faGamepad} />
                                </div>
                                <h2>
                                    Skill and Expertise
                                </h2>
                            </div>
                            <div className='content-body'>
                                <p>
                                    Our boosters are not just skilled gamers; they are experts in their respective domains. Each member of our team has undergone rigorous assessments and evaluations to demonstrate their proficiency and in-depth knowledge of the games we support.
                                </p>
                            </div>
                        </div>
                        <div className={`content-box${selected==='experience' ? ' active fade-animation' : ' inactive'}`}>
                            <div className='content-subtitle'>
                                <div className='content-icon'>
                                    <FontAwesomeIcon icon={faUserGraduate} />
                                </div>
                                <h2>
                                Experience Matters
                                </h2>
                            </div>
                            <div className='content-body'>
                                <p>
                                    We prioritize experience to ensure that our boosters navigate the gaming landscape with finesse. Many of our team members bring years of boosting experience, contributing to our legacy of excellence spanning a decade.
                                </p>
                            </div>
                        </div>
                        <div className={`content-box${selected==='professionalism' ? ' active fade-animation' : ' inactive'}`}>
                            <div className='content-subtitle'>
                                <div className='content-icon'>
                                    <FontAwesomeIcon icon={faBusinessTime} />
                                </div>
                                <h2>
                                    Professionalism
                                </h2>
                            </div>
                            <div className='content-body'>
                                <p>
                                    We believe in maintaining the highest standards of professionalism. Our boosters are not only exceptional players but also courteous and respectful individuals who understand the importance of representing EloFactory.gg with integrity.
                                </p>
                            </div>
                        </div>
                        <div className={`content-box${selected==='selective' ? ' active fade-animation' : ' inactive'}`}>
                            <div className='content-subtitle'>
                                <div className='content-icon'>
                                    <FontAwesomeIcon icon={faUsersGear} />
                                </div>
                                <h2>
                                    Selective Recruitment
                                </h2>
                            </div>
                            <div className='content-body'>
                                <p>
                                    Unlike other services, we don't compromise on quality by randomly selecting boosters. Our recruitment process involves a careful review of gaming credentials, communication skills, and a commitment to our core values.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='blue-line'/>
                </div>

                <div className='content-item background'>
                    <div className='content-container title fill-special'>
                        <h2>Choosing the Best</h2>
                        <h3>OUR BOOSTERS</h3>
                    </div>
                    <div className='content-container'>
                        <div className='content-box icon'>
                            <div className='content-icon'>
                                <FontAwesomeIcon icon={faRankingStar} />
                            </div>
                            <div className='content-subtitle'>
                                <h3>
                                    QUALITY ASSURANCE
                                </h3>
                            </div>
                            <div className='content-body'>
                                <p>
                                    By maintaining a selective approach, we guarantee the quality of our services. Every booster on our team has proven themselves not only as exceptional players but also as professionals who prioritize our clients' satisfaction.
                                </p>
                            </div>
                        </div>
                        <div className='content-box icon'>
                            <div className='content-icon'>
                                <FontAwesomeIcon icon={faUserShield} />
                            </div>
                            <div className='content-subtitle'>
                                <h3>
                                    SECURITY & TRUST
                                </h3>
                            </div>
                            <div className='content-body'>
                                <p>
                                    Your gaming account's security is of utmost importance to us. Our selective process minimizes the risk associated with account sharing and ensures that only trustworthy individuals are entrusted with your gaming progress.
                                </p>
                            </div>
                        </div>
                        <div className='content-box icon'>
                            <div className='content-icon'>
                                <FontAwesomeIcon icon={faGears} />
                            </div>
                            <div className='content-subtitle'>
                                <h3>
                                    CUSTOMIZED SERVICE
                                </h3>
                            </div>
                            <div className='content-body'>
                                <p>
                                    We understand that each player has unique goals and preferences. Our selective approach enables us to match you with a booster who aligns with your specific needs, providing a tailored and effective boosting experience.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='blue-line'/>
                </div>
                
                <div className='content-item'>
                    <div className='content-container title'>
                        <h2>Our Background</h2>
                        <h3>YOUR TRUSTED PARTNER IN THE GAMING WORLD</h3>
                    </div>
                    <div className='content-container'>
                        <div className='content-box special-case'>
                            <div className='content-title'>
                                <h2>Your Boosting Partner</h2>
                            </div>
                            <div className='content-body'>
                                <p>
                                    Welcome to EloFactory.gg, your go-to destination for top-tier gaming boosting services. With a decade of experience, we're committed to helping players enhance their skills and reach new heights in their favorite games.
                                </p>
                            </div>
                        </div>
                        <div className='content-box special-case'>
                            <div className='content-title'>
                                <h2>A Decade of Mastery</h2>
                            </div>
                            <div className='content-body'>
                                <p>
                                At EloFactory.gg, we bring a wealth of knowledge and expertise to the table, backed by 10 years of relentless dedication to the gaming community.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className='content-item'>
                    <div id='FAQ' className='content-container title left'>
                        <h2>FAQ</h2>
                        <h3>FREQUENTLY ASKED QUESTIONS</h3>
                    </div>
                    <div className='content-container left' style={{alignSelf:'stretch', flexDirection:'row'}}>
                        <div className='accordion-container'>
                            <div className='accordion-item'>
                                <div className='accordion-title' onClick={() => toggleAccordion('safe')}>
                                    <h2>Is using a boosting service safe for my account?</h2>
                                    <FontAwesomeIcon icon={active === 'safe' ? faMinusCircle : faPlusCircle} />
                                </div>
                                <div className={`accordion-body${active === 'safe' ? ' active' : ''}`}>
                                    <p>
                                    Yes, at EloFactory.gg, we prioritize the security and integrity of your gaming account. Our team of experienced boosters is trained to adhere to strict ethical standards and employs secure practices during the entire boosting process. We use industry-leading encryption methods, and our VPN protection ensures that your account remains confidential and protected. Additionally, our boosters are professionals who understand the importance of account security, and we take every precaution to minimize any potential risks associated with the boosting service. Rest assured, your account's safety is our top priority.
                                    </p>
                                </div>
                            </div>
                            <div className='accordion-item'>
                                <div className='accordion-title' onClick={() => toggleAccordion('choose')}>
                                    <h2>Why should I choose EloFactory.gg?</h2>
                                    <FontAwesomeIcon icon={active === 'choose' ? faMinusCircle : faPlusCircle} />
                                </div>
                                <div className={`accordion-body${active === 'choose' ? ' active' : ''}`}>
                                    <div>
                                        <p>
                                            At EloFactory.gg, we distinguish ourselves through a combination of experience, excellence, and a commitment to your gaming success. Here's why choosing us is the right decision:
                                        </p>
                                        <ul>
                                            <li>
                                                <p>
                                                    A Decade of Expertise
                                                </p> 
                                                With 10 years of experience, we bring a wealth of knowledge and mastery to the gaming arena, ensuring that our services are backed by a proven track record of success.
                                            </li>
                                            <li>
                                                <p>
                                                    Selective Booster Process
                                                </p> 
                                                We don't just pick any booster. Our stringent selection process ensures that only the most skilled, trustworthy, and experienced gamers join our team, providing you with top-tier assistance.
                                            </li>
                                            <li>
                                                <p>
                                                    Comprehensive Security Measures
                                                </p> 
                                                Your account's safety is our priority. We employ advanced VPN protection, an "Appear Offline" feature, and secure practices to guarantee the confidentiality and security of your gaming progress.
                                            </li>
                                            <li>
                                                <p>
                                                    Personalized and Transparent Service
                                                </p> 
                                                We understand that every gamer is unique. Our services are customizable to meet your specific needs, and our real-time tracking system keeps you informed about the progress of your order at all times.
                                            </li>
                                            <li>
                                                <p>
                                                    Dedicated Customer Support
                                                </p> 
                                                Our customer support team is available 24/7 to address your queries, provide assistance, and ensure that your experience with EloFactory.gg exceeds expectations.
                                            </li>
                                            <li>
                                                <p>
                                                    Wide Range of Services
                                                </p> 
                                                Whether you're aiming for a higher rank, seeking personalized coaching, or pursuing in-game achievements, we offer a diverse array of services tailored to your gaming goals.
                                            </li>
                                            <li>
                                                <p>
                                                    Ethical and Professional Practices
                                                </p> 
                                                We adhere to a strict code of ethics, ensuring that our boosting services are not only effective but also conducted with the utmost professionalism and integrity.
                                            </li>
                                        </ul>   
                                    </div>
                                </div>
                            </div>
                            <div className='accordion-item'>
                                <div className='accordion-title' onClick={() => toggleAccordion('banned')}>
                                    <h2>Can I get banned for using a boosting service?</h2>
                                    <FontAwesomeIcon icon={active === 'banned' ? faMinusCircle : faPlusCircle} />
                                </div>
                                <div className={`accordion-body${active === 'banned' ? ' active' : ''}`}>
                                    <div>
                                        <p>
                                            We understand the concerns related to potential penalties from game publishers. Here's what you need to know:
                                        </p>
                                        <ul>
                                            <li>
                                                <p>
                                                    Our Commitment to Safety
                                                </p> 
                                                We employ a range of security measures, including VPN protection, to ensure the confidentiality and integrity of your gaming account during the boosting process. Our boosters are professionals who adhere to ethical standards and secure practices to minimize any risks associated with using our services.
                                            </li>
                                            <li>
                                                <p>
                                                    Industry Best Practices
                                                </p> 
                                                While we take every precaution to mitigate the risk of penalties, it's important to note that the use of any external service carries some inherent risk. Game publishers may have policies against certain practices, including boosting. We cannot guarantee immunity from actions taken by game publishers, but our commitment to safety is unwavering.
                                            </li>
                                        </ul>
                                        <p>
                                        In summary, while we take significant measures to minimize the risk of penalties, the use of any boosting service carries inherent uncertainties. We advise clients to be aware of the potential risks and make informed decisions. If you have specific concerns or questions, our customer support team is here to provide guidance and address any queries you may have.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='accordion-item'>
                                <div className='accordion-title' onClick={() => toggleAccordion('play')}>
                                    <h2>Can I play on the account while I have an active boost?</h2>
                                    <FontAwesomeIcon icon={active === 'play' ? faMinusCircle : faPlusCircle} />
                                </div>
                                <div className={`accordion-body${active === 'play' ? ' active' : ''}`}>
                                    <div>
                                        <p>
                                            Yes, we understand the importance of maintaining flexibility and control over your gaming experience. While your boost is active, you can still access and enjoy your account. Here are some tips to ensure a seamless experience:
                                        </p>
                                        <ul>
                                            <li>
                                                <p>
                                                    Coordination with Booster
                                                </p> 
                                                If you plan to play on the account simultaneously with an active boost, we recommend coordinating schedules with your assigned booster. This helps prevent any potential conflicts and ensures a smooth gaming experience for both you and the booster.
                                            </li>
                                            <li>
                                                <p>
                                                    Communication is Key
                                                </p> 
                                                Clear communication is crucial. Let your booster know when you plan to use the account so that they can adjust their boosting schedule accordingly. Our team is dedicated to accommodating your preferences whenever possible.
                                            </li>
                                            <li>
                                                <p>
                                                    Appear Offline Option
                                                </p> 
                                                For added privacy, particularly in multiplayer games, take advantage of our "Appear Offline" feature. This option allows you to maintain discretion by ensuring your boost remains confidential. Simultaneously, you can freely enjoy playing on another account without drawing unnecessary attention. It's the perfect balance of seamless boosting and uninterrupted gaming.
                                            </li>
                                            <li>
                                                <p>
                                                    Real-time Tracking
                                                </p> 
                                                Our order tracking system allows you to monitor the progress of your boost in real-time. This visibility ensures that you are always aware of the status of your order, allowing for better coordination between you and your booster.
                                            </li>
                                        </ul>
                                        <p>
                                            Remember, our goal is to provide a boosting service that aligns with your gaming preferences. If you have specific requests or questions about playing on your account during an active boost, our customer support team is available 24/7 to assist you.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='accordion-item'>
                                <div className='accordion-title' onClick={() => toggleAccordion('long')}>
                                    <h2>How long does the boosting process take?</h2>
                                    <FontAwesomeIcon icon={active === 'long' ? faMinusCircle : faPlusCircle} />
                                </div>
                                <div className={`accordion-body${active === 'long' ? ' active' : ''}`}>
                                    <p>
                                        The duration of the boosting process can vary depending on several factors, including the specific service selected, the current state of your account, and the complexity of your gaming goals. Our team is committed to completing boosts efficiently without compromising quality. For an estimate tailored to your order, please contact our customer support team. Rest assured, we strive to provide a timely and satisfactory experience for every client.    
                                    </p>
                                </div>
                            </div>
                            <div className='accordion-item'>
                                <div className='accordion-title' onClick={() => toggleAccordion('support')}>
                                    <h2>How do I contact customer support if I have questions or concerns?</h2>
                                    <FontAwesomeIcon icon={active === 'support' ? faMinusCircle : faPlusCircle} />
                                </div>
                                <div className={`accordion-body${active === 'support' ? ' active' : ''}`}>
                                    <p>
                                        To reach our customer support team, simply create a ticket on our Discord server. This efficient system ensures that your inquiries or concerns are promptly addressed by our knowledgeable and dedicated support staff. We value open communication and are committed to providing you with timely assistance throughout your experience with us.
                                    </p>
                                </div>
                            </div>
                            <div className='accordion-item'>
                                <div className='accordion-title' onClick={() => toggleAccordion('refunds')}>
                                    <h2>Are there any guarantees or refunds if I'm not satisfied with the service?</h2>
                                    <FontAwesomeIcon icon={active === 'refunds' ? faMinusCircle : faPlusCircle} />
                                </div>
                                <div className={`accordion-body${active === 'refunds' ? ' active' : ''}`}>
                                    <p>
                                        Absolutely. At EloFactory.gg, we stand behind the quality of our services. If, for any reason, you are not satisfied with the outcome of your boost, we offer a satisfaction guarantee. Our commitment is to ensure that you receive the service you expect and deserve. If issues arise, please contact our customer support team, and we will work diligently to address your concerns or provide a refund, in accordance with our refund policy. Your satisfaction is our priority, and we are dedicated to making your experience with us positive and fulfilling.
                                    </p>
                                </div>
                            </div>
                            <div className='accordion-item'>
                                <div className='accordion-title' onClick={() => toggleAccordion('confidential')}>
                                    <h2>Is my personal information kept confidential?</h2>
                                    <FontAwesomeIcon icon={active === 'confidential' ? faMinusCircle : faPlusCircle} />
                                </div>
                                <div className={`accordion-body${active === 'confidential' ? ' active' : ''}`}>
                                    <p>
                                        Absolutely. Safeguarding your privacy is a top priority at EloFactory.gg. We adhere to stringent data protection measures to ensure the confidentiality and security of your personal information. Rest assured that any details shared with us, including account credentials, are handled with the utmost care and are strictly used for the purpose of providing our boosting services. For more details on how we handle personal information, you can review our comprehensive privacy policy or contact our customer support team for further assistance. Your trust is crucial to us, and we take every precaution to maintain the privacy and security of your data.
                                    </p>
                                </div>
                            </div>
                            <div className='accordion-item'>
                                <div className='accordion-title' onClick={() => toggleAccordion('coaching')}>
                                    <h2>Do you offer coaching services in addition to boosting?</h2>
                                    <FontAwesomeIcon icon={active === 'coaching' ? faMinusCircle : faPlusCircle} />
                                </div>
                                <div className={`accordion-body${active === 'coaching' ? ' active' : ''}`}>
                                    <div>
                                        <p>
                                            Absolutely! At EloFactory.gg we recognize the significance of coaching for skill enhancement. Alongside our boosting services, we provide personalized coaching sessions.
                                        </p>
                                        <ul>
                                            <li>
                                                <p>
                                                    Specialized Coaching Team
                                                </p> 
                                                Not every booster at EloFactory.gg is a coach. We have a select team of individuals who excel not just in gaming but also in teaching. Each coach undergoes a stringent selection process to guarantee effective knowledge transfer.
                                            </li>
                                            <li>
                                                <p>
                                                    Emphasis on Teaching Excellence
                                                </p> 
                                                We require our coaches to possess exceptional teaching skills. Beyond gaming proficiency, they excel in communication and adapt their teaching style to suit your learning preferences.
                                            </li>
                                        </ul>
                                        <p>
                                            Whether you're aiming to climb ranks, refine specific skills, or gain deeper game insights, our coaching services are designed to empower you. Explore our coaching options on the website to schedule a session. Elevate your skills with confidence at EloFactory.gg
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='accordion-item'>
                                <div className='accordion-title' onClick={() => toggleAccordion('payment')}>
                                    <h2>What payment methods do you accept?</h2>
                                    <FontAwesomeIcon icon={active === 'payment' ? faMinusCircle : faPlusCircle} />
                                </div>
                                <div className={`accordion-body${active === 'payment' ? ' active' : ''}`}>
                                    <p>
                                        We use Stripe, a secure and trusted payment processing platform, to process all payments. Stripe accepts all major credit cards, including Visa, Mastercard, American Express. If you have any questions or concerns about payment methods, please contact our customer support team for assistance.
                                    </p>
                                </div>
                            </div>
                            <div className='accordion-item'>
                                <div className='accordion-title' onClick={() => toggleAccordion('skin')}>
                                    <h2>How do I claim my free skin?</h2>
                                    <FontAwesomeIcon icon={active === 'skin' ? faMinusCircle : faPlusCircle} />
                                </div>
                                <div className={`accordion-body${active === 'skin' ? ' active' : ''}`}>
                                    <div>
                                        <p>
                                            At EloFactory.gg, we appreciate your feedback and want to reward you for taking the time to share your experience. To receive a free skin, follow these steps:
                                        </p>
                                        <ul>
                                            <li>
                                                <p>
                                                    Leave a Review on Trustpilot
                                                </p> 
                                                After purchasing one of our services for at least $20 USD, please leave a review on Trustpilot. We appreciate all feedback, whether it’s positive, neutral, or negative.                                            </li>
                                            <li>
                                                <p>
                                                    Open a Ticket
                                                </p> 
                                                Open a support ticket on our platform and provide proof of your Trustpilot review. This can be a screenshot or any other relevant evidence.
                                            </li>
                                            <li>
                                                <p>
                                                    Claim Your Free Skin
                                                </p> 
                                                Once we verify your review, our team will ensure you receive a free skin as a token of our appreciation.
                                            </li>
                                        </ul>
                                        <p>
                                            Your feedback is crucial to us, and we want to thank you for being a part of EloFactory.gg. We look forward to hearing about your experience and providing you with excellent service.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='accordion-item'>
                                <div className='accordion-title' onClick={() => toggleAccordion('touch')}>
                                    <h2>I have a question that isn't listed here. How do I get in touch?</h2>
                                    <FontAwesomeIcon icon={active === 'touch' ? faMinusCircle : faPlusCircle} />
                                </div>
                                <div className={`accordion-body${active === 'touch' ? ' active' : ''}`}>
                                    <p>
                                        If you have any questions or concerns that are not addressed here, please contact our customer support team, join our discord. We are available 24/7 to assist you and ensure that your experience with EloFactory.gg exceeds expectations.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    );
}